<template>
  <v-card elevation="0">
    <v-card-text>
      <v-switch
        v-model="showEventHours"
        data-cy="showEventHours"
        label="Affiche des heures"
        hide-details
        color="primary"
      ></v-switch>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { setShowEventHoursEventSettings } from '/imports/api/methods/settings';
import log from 'loglevel';
import { useStore } from 'vuex';
import useEmitter from '../../EventBus';

const store = useStore();
const EventBus = useEmitter();
const emit = defineEmits(['close']);

let showEventHours = ref(false);

watch(showEventHours, (value) => {
  log.info('update:showEventHours', value);
  save();
  //  emit('update:showEventHours', value);
});

onMounted(() => {
  log.info('ViewEventsSettings mounted');
  const settings = store.state.settings;
  showEventHours.value = settings.calendar.event.showEventHours;
});
/**
 * Handles the close button click event.
 * Emits two events:
 * 1. 'update:dayBoundaries' with the start and end hour values.
 * 2. 'update:modelValue' with a boolean value of false.
 */

async function save() {
  await setShowEventHoursEventSettings.callAsync({
    showEventHours: showEventHours.value
  });
  store.commit('setShowEventHoursEventSettings', showEventHours.value);
  EventBus.emit('update:userCalendarSettings');
  emit('close');
}
</script>

<style></style>
